<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title
          >Detail Tabungan
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>

          <router-link :to="{ name: 'daftar-tabungan' }">
            <v-btn small color="primary">Kembali</v-btn>
          </router-link>
        </v-card-title>
        <v-simple-table dense style="width: 100%">
          <tbody>
            <tr>
              <td><b>Kode Anggota</b> : {{ item.kode_anggota }}</td>
              <td><b>Nama</b> : {{ item.nama }}</td>
              <td>
                <b>Wilayah</b> :
                <span v-if="item.wilayah_luar === '1'" style="color: red">{{ item.wilayah }}</span>
                <span v-else>{{ item.wilayah }}</span>
              </td>
            </tr>
            <tr>
              <td><b>Tgl Lahir</b> : {{ formatDate(item.tgl_lahir) }}</td>
              <td><b>NIK</b> : {{ item.nik }}</td>
              <td><b>Jenis Upah</b> : {{ item.jenis_upah }}</td>
            </tr>
            <tr>
              <td><b>Tgl Masuk</b> : {{ formatDate(item.tgl_masuk) }}</td>
              <td><b>Jabatan</b> : {{ item.jabatan }}</td>
              <td><b>Jumlah Upah : </b>{{ new Intl.NumberFormat(['id']).format(item.jumlah_upah) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table dense style="width: 100%; margin-top: 20px">
          <tbody>
            <tr>
              <td><b>Program</b> : {{ item.nama_jenis }}</td>
              <td><b>Besar Tabungan</b> : {{ new Intl.NumberFormat(['id']).format(item.besar_tabungan) }}</td>
              <td><b>Bunga (%)</b> : {{ item.persen_bunga }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-data-table dense :headers="headers" :items="desserts" :loading="loading" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <!-- dialog tambah -->
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Tambah </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Tambah Tabungan</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            label="Besar Tabungan"
                            v-model="editedItem.besar_tabungan"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" style="font-weight: bold; color: red">
                          {{ new Intl.NumberFormat(['id']).format(editedItem.besar_tabungan) }}
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <v-btn color="primary" :loading="property.save_button" @click="save"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- dialog ambil -->
              <v-dialog v-model="dialogAmbil" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark class="mb-2 ml-4" v-bind="attrs" v-on="on"> Ambil </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Ambil Tabungan</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="formAmbil.besar_tabungan"
                            label="Besar Ambil"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                          <div class="ml-4" style="font-weight: bold; color: red">
                            {{ new Intl.NumberFormat(['id']).format(formAmbil.besar_tabungan) }}
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Keterangan"
                            v-model="formAmbil.keterangan"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="dialogAmbil = false"> Batal </v-btn>
                    <v-btn color="primary" @click="ambil"> Ambil </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ formatDateTime(item.created_at) }}
          </template>
          <template v-slot:item.besar_tabungan="{ item }">
            {{ new Intl.NumberFormat(['id']).format(item.besar_tabungan) }}
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-btn x-small v-if="user.is_superadmin == true" @click="deleteItem(item)" color="error" title="Hapus">
              Hapus
            </v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn small color="primary" @click="initialize"> Reload</v-btn>
          </template>
        </v-data-table>
        <!-- dialog delete -->
        <v-dialog persistent v-model="dialogDelete" max-width="350">
          <v-card>
            <v-card-title class="headline">Konfirmasi</v-card-title>
            <v-card-text>
              <h3 class="mb-4">Apakah yakin akan menghapus?</h3>
              <v-text-field
                v-model="pin_super"
                type="password"
                outlined
                hide-details=""
                dense
                label="Masukkan pin:"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="closeDelete()">Batal</v-btn>
              <v-btn color="error" @click="commitDeleteItem()">Hapus</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      id_item: this.$route.params.id_tabungan,
      item: {},
      property: {
        save_button: false,
      },

      // datatable
      desserts: [],
      loading: true,
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Besar Tabungan', value: 'besar_tabungan', sortable: false, align: 'right' },
        { text: 'Jenis', value: 'jenis', sortable: false },
        { text: 'Keterangan', value: 'keterangan', sortable: false },
        { text: 'User Entry', value: 'user_entry', sortable: false },
        { text: 'Waktu', value: 'created_at', sortable: false },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],

      dialog: false,
      editedItem: {
        id: '',
        besar_tabungan: 0,
      },
      defaultItem: {
        id: '',
        besar_tabungan: 0,
      },
      formAmbil: {
        besar_tabungan: 0,
        keterangan: '',
      },
      dialogAmbil: false,
      dialogDelete: false,
      pin_super: '',
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
    user() {
      return this.$store.getters.user
    },
  },
  methods: {
    initialize() {
      this.getDataPinjaman()
    },
    close() {
      this.dialog = false
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    },
    getDataPinjaman() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Tabungan/getById/${this.id_item}`, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.item = Object.assign({}, response.data.data)
            this.editedItem.besar_tabungan = response.data.data.besar_default
            this.getTabunganitems()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },

    getTabunganitems() {
      this.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_tabungan', this.id_item)
      // form.append('search', this.search)
      // form.append('page', page)
      // form.append('itemsPerPage', itemsPerPage)
      axios
        .post(`${apiRoot}/api/Tabungan/getDetailTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
          } else {
            // alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },

    save() {
      this.property.save_button = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_tabungan', this.id_item)
      form.append('besar_tabungan', this.editedItem.besar_tabungan)
      axios
        .post(`${apiRoot}/api/Tabungan/addItem`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getTabunganitems()
            this.getDataPinjaman()
            this.dialog = false
          } else {
            alert(response.data.message)
          }
          this.property.save_button = false
        })
        .catch(error => {
          alert(error)
          this.property.save_button = false
        })
    },
    ambil() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_tabungan', this.id_item)
      form.append('besar_tabungan', this.formAmbil.besar_tabungan)
      form.append('keterangan', this.formAmbil.keterangan)
      axios
        .post(`${apiRoot}/api/Tabungan/addItemKredit`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getTabunganitems()
            this.getDataPinjaman()
            this.dialogAmbil = false
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    deleteItem(item) {
      this.editedItem.id = item.id
      this.pin_super = ''
      this.dialogDelete = true
    },
    commitDeleteItem() {
      this.$store.state.loadingOverlay = true

      const fmData = new FormData()
      fmData.append('kode_anggota', this.item.kode_anggota)
      fmData.append('id_item_tabungan', this.editedItem.id)
      fmData.append('pin', this.pin_super)
      axiosPostAuth('api/Tabungan/hapusItemTabungan', fmData)
        .then(response => {
          if (response.code === 200) {
            this.getDataPinjaman()
            this.closeDelete()
          } else {
            alert(response.message)
          }
          this.$store.state.loadingOverlay = false
        })
        .catch(error => {
          alert(error)
          this.$store.state.loadingOverlay = false
        })
    },
    closeDelete() {
      this.editedItem = this.defaultItem
      this.dialogDelete = false
    },
  },
}
</script>
